"use client";

import { getStyle } from "@/utils/styles";
import Image from "next/image";
import { useState } from "react";
import { FaPlay } from "react-icons/fa";

type Props = {
  youtubeId: string;
  style: string;
};

const YoutubePlayer = ({ style, youtubeId }: Props) => {
  if (youtubeId.split("/").length > 1) {
    let temp = youtubeId.split("/");
    youtubeId = temp[temp.length - 1];
  }
  const [video, setVideo] = useState(false);
  return (
    <section
      className={`w-full lg:px-4 lg:py-12 ${getStyle(style).bg} ${
        getStyle(style).text
      }`}
    >
      <div className="mx-auto aspect-[36/19] w-full max-w-[920px]">
        {!video ? (
          <div
            onClick={() => {
              setVideo(!video);
            }}
            className="group relative h-full w-full cursor-pointer"
          >
            <div className="absolute left-[50%] top-[50%] z-10 flex h-20 w-20 -translate-x-[50%] -translate-y-[50%] items-center justify-center rounded-full bg-primary/80 transition-all duration-300 group-hover:scale-110 group-hover:bg-primary">
              <FaPlay className="ml-0.5 h-6 w-6 text-white opacity-70 transition-all duration-300 group-hover:opacity-100 " />
            </div>
            <Image
              src={`https://i3.ytimg.com/vi/${youtubeId}/hqdefault.jpg`}
              width={1279}
              height={675}
              alt=""
              className="relative h-full w-full object-cover"
            />
          </div>
        ) : (
          <iframe
            width="100%"
            height="100%"
            src={`https://www.youtube.com/embed/${youtubeId}?autoplay=1&amp;origin=undefined`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        )}
      </div>
    </section>
  );
};

export default YoutubePlayer;
