"use client";
import { ImageProps } from "@/types/image";
import Carousel from "react-multi-carousel";
import CImage from "../CImage";
import ItemError from "../error/ItemError";
const responsive = {
  desktop: {
    breakpoint: { max: 3600, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 767, min: 0 },
    items: 1,
  },
};

type Props = {
  sliderImageUrl: ImageProps[];
};

const ImageSliderMobile = ({ sliderImageUrl }: Props) => {
  try {
    return (
      <div className="image-slider">
        <Carousel
          responsive={responsive}
          autoPlay={true}
          swipeable={true}
          draggable={true}
          showDots={true}
          infinite={true}
          partialVisible={false}
          autoPlaySpeed={6000}
        >
          {sliderImageUrl.map((image, index) => {
            return (
              <CImage
                src={image.url}
                alt={image.alt}
                width={764}
                height={400}
                crop={false}
                className="h-[300px] w-full object-cover lg:h-[500px]"
                priority={false}
                quality={90}
                base64={image.base64}
              />
            );
          })}
        </Carousel>
      </div>
    );
  } catch (error) {
    return <ItemError __component="Image Slider Error" />;
  }
};

export default ImageSliderMobile;
