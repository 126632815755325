"use client";

import { MonitoringEvent } from "@/utils/events";
import { useEffect } from "react";

type Props = {};

const ComponentError = ({ id, __component }: any) => {
  const sendEvent = async () => {
    await MonitoringEvent(__component, id);
  };
  useEffect(() => {
    sendEvent();
  }, []);

  if (process.env.NEXT_PUBLIC_STAGE === "development") {
    return (
      <section className="w-full p-6 py-12 md:py-16">
        <div className="container relative mx-auto flex animate-pulse flex-col items-start justify-start space-y-4 overflow-hidden rounded-lg bg-gradient-to-b from-red-500 to-red-700 p-10 text-white duration-1000">
          <p className="absolute -top-8 right-8 text-[160px] font-bold text-white/20">
            =(
          </p>
          <p className="text-7xl font-extrabold text-white/50 ">Error</p>
          <span>
            <p className="text-lg font-semibold">{id}</p>
            <p>{__component}</p>
          </span>
        </div>
      </section>
    );
  }
  return <></>;
};

export default ComponentError;
