"use client";
import { FuneralTypeItem } from "@/types/landingPage/content/funeralType";
import { StatsigSendEvent } from "@/utils/events";
import { getWizardUrl } from "@/utils/wizard";
import CImage from "../CImage";
import ItemError from "../error/ItemError";

type Props = {
  __component?: string;
  style: string;
  buttonPrefix: string;
  overrideButtonId?: string | null;
};

const Price5Item = ({
  style,
  image,
  label,
  plans,
  funeralType,
  link,
  buttonPrefix,
  overrideButtonId,
}: FuneralTypeItem & Props) => {
  let buttonId = overrideButtonId
    ? overrideButtonId
    : buttonPrefix?.length > 2
    ? `${buttonPrefix.toLowerCase()}_${funeralType.toLowerCase()}`
    : null;
  const handlerEvent = async () => {
    if (buttonId) {
      await StatsigSendEvent(buttonId);
    }
  };
  const wizardUrl = getWizardUrl();
  try {
    return (
      <a
        className="group relative flex h-52 w-full flex-grow cursor-pointer flex-col overflow-hidden text-white md:h-full"
        href={wizardUrl}
        target="_self"
        onClick={handlerEvent}
        id={buttonId ? buttonId : ""}
      >
        <CImage
          src={image.url}
          width={400}
          height={300}
          className="relative h-full w-full scale-105 object-cover transition-all duration-300 group-hover:scale-125 "
          crop={true}
          // priority={true}
        />
        <div className=" absolute bottom-0 flex h-auto w-full flex-row items-start justify-between bg-black bg-opacity-50 px-4 py-2 backdrop-blur-sm md:h-32 md:items-center">
          <span className="flex flex-col ">
            <p className=" text-xl font-bold">{label}</p>
            <span className="leading-4">
              ab <span className="text-lg font-bold">{plans[0].price} €</span>,{" "}
              <br className=" hidden md:block" />
              exkl. externe Kosten
            </span>
          </span>
          <span className="my-auto">
            <svg
              width="14"
              height="25"
              viewBox="0 0 14 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 23.2059L11.5536 12.7582L2 2.31054"
                stroke="#FAFAFA"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
      </a>
    );
  } catch (error) {
    return <ItemError __component="Price 5 item error" />;
  }
};

export default Price5Item;
