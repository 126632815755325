"use client";

import { IconList } from "@/types/icons";
import { FuneralTypeItem } from "@/types/landingPage/content/funeralType";
import { StatsigSendEvent } from "@/utils/events";
import { getStyle } from "@/utils/styles";
import { getWizardUrl } from "@/utils/wizard";
import Icons from "../Icons";
import ItemError from "../error/ItemError";

type Props = {
  __component?: string;
  style: string;
  funeralType: FuneralTypeItem;
  buttonPrefix: string;
  overrideButtonId?: string | null;
};

const Price3Item = ({
  style,
  funeralType,
  buttonPrefix,
  overrideButtonId,
}: Props) => {
  let buttonId = overrideButtonId
    ? overrideButtonId
    : buttonPrefix?.length > 2
    ? `${buttonPrefix}${funeralType.funeralType.toLowerCase()}`
    : null;

  const handlerEvent = async () => {
    if (buttonId) {
      await StatsigSendEvent(buttonId);
    }
  };
  try {
    return (
      <a
        className="flex w-auto cursor-pointer flex-row items-center justify-start space-x-2 hover:underline"
        href={getWizardUrl()}
        id={buttonId ? buttonId : ""}
        onClick={handlerEvent}
      >
        <span
          className={`flex h-12 w-12 items-center justify-center rounded-full ${
            getStyle(style).bgInvert
          } ${getStyle(style).textInvert}`}
        >
          <Icons icon={funeralType.funeralType as IconList} />
        </span>
        <span className="flex flex-col items-start justify-start">
          <p className="text-lg font-bold underline">{funeralType.label}</p>
          <p className="text-base">
            ab <span className="font-bold">{funeralType.plans[0].price} €</span>
            , exkl. externe Kosten
          </p>
        </span>
      </a>
    );
  } catch (error) {
    return <ItemError __component="Price 3 item error" />;
  }
};

export default Price3Item;
