"use client";
import { FuneralPricePlan } from "@/types/landingPage/content/funeralPrice";
import { StatsigSendEvent } from "@/utils/events";
import { getStyle } from "@/utils/styles";
import { getWizardUrl } from "@/utils/wizard";
import ItemError from "../error/ItemError";

type Props = {
  __component?: string;
  style: string;
  plan: FuneralPricePlan;
  buttonPrefix: string;
  overrideButtonId?: string | null;
};

const Price4Item = ({ style, plan, buttonPrefix, overrideButtonId }: Props) => {
  let buttonId = overrideButtonId
    ? overrideButtonId
    : buttonPrefix?.length > 2
    ? `${buttonPrefix.toLowerCase()}_${plan.planName.toLowerCase()}`
    : null;

  const handlerEvent = async () => {
    if (buttonId) {
      await StatsigSendEvent(buttonId);
    }
  };
  try {
    return (
      <a
        className="flex w-full cursor-pointer flex-col items-center justify-start border border-black/10 md:flex-row"
        href={getWizardUrl()}
        id={buttonId ? buttonId : ""}
        onClick={handlerEvent}
      >
        <div
          className={`flex h-full w-full flex-row items-center p-4 md:w-2/5 ${
            getStyle(style).bgInvert
          } ${getStyle(style).textInvert}`}
        >
          <span className="flex h-full w-full flex-col  items-start justify-between ">
            <p className="text-2xl font-bold">{plan.planName}</p>
            <span>
              <p>
                ab <span className="text-2xl font-bold">{plan.price} €</span>
              </p>
              <p className="text-sm">exkl. externe Kosten</p>
            </span>
            <p className="underline">Jetzt planen</p>
          </span>
          <span>
            <svg
              width="14"
              height="25"
              viewBox="0 0 14 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 23.2059L11.5536 12.7582L2 2.31054"
                stroke="#FAFAFA"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
        </div>
        <span
          className={`flex w-full flex-col items-start justify-start space-y-4 bg-white p-4 md:w-3/5  ${
            getStyle(style).htmlContent
          }`}
          dangerouslySetInnerHTML={{ __html: plan.content }}
        ></span>
      </a>
    );
  } catch (error) {
    return <ItemError __component="Price 4 item error" />;
  }
};

export default Price4Item;
